<template>
  <b-row>
    <b-col class="register-success">
      <div class="register-success__logo broker" />

      <main>
        <h1 class="register-success__title">
          Parabéns, seus dados foram encaminhados para análise!
        </h1>
        <p class="register-success__paragraph-broker">
          Iremos avaliar a sua documentação e em breve retornaremos o contato,
          ficamos muito felizes em saber que você quer ser um dos corretores da
          Divvy!
        </p>
      </main>

      <footer>
        <div class="register-success__confirmed-button broker-color">
          <feather-icon icon="CheckIcon" class="w-100 h-100" />
        </div>
        <b-link :to="{ name: 'broker-login' }">
          <span class="register-success__login-return">
            Voltar
          </span>
        </b-link>
      </footer>
    </b-col>

    <b-col class="register-background">
      <div class="register-background-text">
        <h1 class="register-background-text__title">
          Compartilhe imóveis e ganhe comissões rápidas e fáceis
          <span class="register-background-text__title--broker">!</span>
        </h1>
        <p class="register-background-text__paragraph">
          Simples, fácil e seguro para qualquer pessoa investir no mercado
          imobiliário.
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BLink } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
  },
}
</script>

<style lang="scss">
@import '../sass/variables.scss';
@import '../sass/register-background-text.scss';
@import '../sass/register-success.scss';

.register-background {
  display: none;

  @media (min-width: 1055px) {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-image: url('~@/assets/images/pages/background_broker.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
  }
}
</style>
